<template>
  <b-row>
    <b-col md="12">
      <b-tabs>

        <b-tab>
          <template #title>
            <feather-icon icon="MailIcon" />
            <span>Invoice</span>
          </template>
          
          <invoice-table
            v-if="invoices.length > 0"
            :items="invoices"
          />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon icon="XCircleIcon" />
            <span>Dispute</span>
          </template>
          
          <dispute-table
            :items="disputes"
          />
        </b-tab>

      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BTabs, BTab,
} from 'bootstrap-vue'
import * as invoiceService from '@/services/invoice'
import InvoiceTable from './InvoiceTable.vue'
import DisputeTable from './DisputeTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    InvoiceTable,
    DisputeTable,
  },
  data() {
    return {
      items: [],
      invoices: [],
      disputes: [],
    }
  },

  computed: {
  },

  created() {
    this.getlists()
  },

  methods: {
    /** get compared result of payments */
    getlists() {
      this.$store.commit('set_loading_action', true)
      invoiceService.getlists()
        .then(res => {
          this.$store.commit('set_loading_action', false)
          // this.invoices = res.data.payments
          this.invoices = res.data.payments.filter(item => item.actual_pay === item.estimated_cost)
          this.disputes = res.data.payments.filter(item => item.actual_pay !== item.estimated_cost)
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('set_loading_action', false)
        })
    },
  },
}
</script>
