<template>
  <b-card-code
    title=""
    no-body
  >
    <b-tabs
      content-class="mt-2"
      align="center"
      pills
    >

      <b-tab
        active
        title="Ready"
      >
        <invoice-ready-table
          :items="ready"
        />
      </b-tab>

      <b-tab title="History">
        <invoice-history-table
          :items="history"
        />
      </b-tab>

    </b-tabs>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import InvoiceReadyTable from './InvoiceReadyTable.vue'
import InvoiceHistoryTable from './InvoiceHistoryTable.vue'

export default {
  components: {
    BCardCode,
    BTabs,
    BTab,
    InvoiceReadyTable,
    InvoiceHistoryTable,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      ready: [],
      history: [],
    }
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.ready = this.items.filter(item => !item.invoice_status)
    this.history = this.items.filter(item => item.invoice_status)
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style>

</style>
