<template>
  <div>
    <div class="d-flex justify-content-end align-item-center flex-wrap mb-2 px-2">
      <b-button
        size="sm"
        class="mr-1"
        variant="primary"
        @click="selectAllRows"
      >
        Select all
      </b-button>
      <b-button
        size="sm"
        class="mr-1"
        variant="primary"
        @click="clearSelected"
      >
        Clear selected
      </b-button>
      <b-button
        v-if="selected.length > 0"
        variant="primary"
        @click="sendInvoice(selected)"
      >
        Send Invoices
      </b-button>
      <b-form-group
        label=""
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>

    <b-table
      ref="selectableTable"
      striped
      hover
      responsive
      selectable
      :select-mode="selectMode"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      @row-selected="onRowSelected"
    >
      <!-- Example scoped slot for select state illustrative purposes -->
      <template #cell(#)="{ rowSelected }">
        <template v-if="rowSelected">
          <feather-icon
            icon="CheckCircleIcon"
            style="color: #28c76f"
            size="14"
          />
        </template>

        <template v-else>
          <feather-icon
            icon="CircleIcon"
            size="14"
          />
        </template>
      </template>

      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>

      <!--  Actual Pay -->
      <template #cell(actual_pay)="row">
        <span v-if="row.item.actual_pay">
          ${{ row.item.actual_pay.toFixed(2) }}
        </span>
      </template>

      <!--  Estimated Cost -->
      <template #cell(estimated_cost)="row">
        <span v-if="row.item.estimated_cost">
          ${{ row.item.estimated_cost.toFixed(2) }}
        </span>
      </template>

      <!--  Balance -->
      <!-- <template #cell(balance)="row">
        <span v-if="row.item.estimated_cost && row.item.actual_pay">
          ${{ (row.item.estimated_cost - row.item.actual_pay).toFixed(2) }}
        </span>
      </template> -->

      <!--  Action -->
      <template #cell(action)="row">
        <b-button
          variant="outline-primary"
          size="sm"
          @click="sendInvoice(row.item)"
        >
          Send Invoice
        </b-button>
      </template>

    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label=""
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody,
} from 'bootstrap-vue'

import * as invoiceService from '@/services/invoice'

export default {
  components: {
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      perPage: 30,
      pageOptions: [20, 30, 40],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        '#',
        { key: 'invoice_number', label: 'Invoice ID' },
        { key: 'block_id', label: 'Block ID' },
        { key: 'trip_id', label: 'Trip ID', sortable: true },
        { key: 'load_id', label: 'Load ID', sortable: true },
        { key: 'actual_pay', label: 'Actual Pay', sortable: true },
        { key: 'estimated_cost', label: 'Estimated Cost', sortable: true },
        // { key: 'balance', label: 'Balance', sortable: true },
        { key: 'action', label: 'Action' },
      ],
      selectMode: 'multi',
      selected: [],
    }
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },

  watch: {
    items(value) {
      this.totalRows = value.length
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    onRowSelected(items) {
      this.selected = items
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    sendInvoice(value) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, upload it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.commit('set_loading_action', true)
          invoiceService.sendInvoice({ payload: value })
            .then(() => {
              this.$store.commit('set_loading_action', false)
              this.$swal({
                title: 'Congratulation!',
                text: 'You sent Invoice succesfully!',
                icon: 'success',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then(() => {
                this.$router.go(this.$router.currentRoute)
              })
            })
            .catch(() => {
              this.$store.commit('set_loading_action', false)
            })
        }
      })
    },
  },
}
</script>

<style>

</style>
